import { Outlet } from 'react-router-dom';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { Layout } from './shared/layout/Layout';

export const App = () => {
    return (
        <>
            <Header />
            <Layout>
                <Outlet />
            </Layout>
            <Footer />
        </>
    );
};
