import { NavLink } from 'react-router-dom';
import cls from './menu.module.scss';

export const Menu = () => {
  return (
    <nav className={cls.menu}>
      <NavLink to='/'>Главная</NavLink>
      <NavLink to='/'>Трейдерам</NavLink>
      <NavLink to='/'>Инвесторам</NavLink>
      <NavLink to='/'>Партнерам</NavLink>
      <NavLink to='/'>Обучение</NavLink>
      <NavLink to='/'>О&nbsp;компании</NavLink>
    </nav>
  );
};
