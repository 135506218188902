// import { ReactComponent as DownloadSVG } from '../../../assets/icons/download.svg';
import { ReactComponent as WhatsUpSVG } from '../../../assets/icons/whatsUp.svg';
import { ReactComponent as TelegramSVG } from '../../../assets/icons/telegram.svg';
import cls from './footerBlock.module.scss';

export const FooterPriceListBlock = () => {
    return (
        <div className={cls.footer__block}>
            <h2 className={cls.footer__title}>Скачать прайс-лист:</h2>
            <button>Кнрпка</button>
            <span className={cls.footer__connection}>Связь в мессенджерах:</span>
            <div className={cls.footer__social}>
                <WhatsUpSVG />
                <TelegramSVG />
            </div>
        </div>
    );
};
