import { Hero } from '../../components/homePage/hero/Hero';
import { Products } from '../../components/homePage/products/Products';
import { Services } from '../../components/homePage/services/Services';
import cls from './HomePage.module.scss';

export const HomePage = () => {
    return (
        <div className={cls.HomePage}>
            <Hero />
            <Products />
            <Services />
        </div>
    );
};
