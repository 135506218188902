import cls from './footerBlock.module.scss';
import { useResize } from '../../../hooks/useRessize';

export const FooterMainBlock = () => {
    const resize450 = useResize(448);
    return (
        <div className={cls.footer__block}>
            {!resize450 && (
                <div className={cls.footer__button}>
                    <button>Временная кнопка</button>
                </div>
            )}
            {/* Логотип */}
            <p className={cls.footer__text}>
                Возможность получения прибыли при совершении операций на финансовых рынках
                неразрывно связана с риском получения убытков. Данная деятельность подходит не всем
                инвесторам и трейдерам. Увеличение кредитного плеча повышает риск при совершении
                операции. Результаты операций в прошлом никак не связаны с возможными результатами в
                будущем и не могут гарантировать безусловное получение дохода по новым операциям
            </p>
            <span className={cls.footer__subscribe}>Подпишись на скидки и акции</span>
        </div>
    );
};
