import cls from './Header.module.scss';
import { ReactComponent as Logo } from '../../img/logo/logo-dark.svg';
import { NavBar } from './nav/NavBar';
import { Layout } from '../../shared/layout/Layout';

export const Header = () => {
    return (
        <div className={cls.header}>
            <Layout>
                <div className={cls.header__wrapper}>
                    <div className={cls.header__logo}>
                        <Logo />
                    </div>
                    <NavBar />
                </div>
            </Layout>
        </div>
    );
};
